
import Link from 'next/link';
import React from 'react';

const Cta = () => {
	return (
    <>
      {/* <!-- CTA --> */}
      <div className='relative z-10 dark:bg-jacarta-900'>
        <div className='container'>
          <div className='relative overflow-hidden rounded-2.5xl px-16 pt-16 pb-8 shadow-md lg:px-24 bg-jacarta-700'>
            {/* <picture className='pointer-events-none absolute inset-0 -z-10 dark:hidden'>
              <img
                src='/images/gradient_light.jpg'
                alt='gradient'
                className='h-full w-full'
              />
            </picture>
            <picture className='pointer-events-none absolute inset-0 -z-10 hidden dark:block'>
              <img
                src='/images/gradient_dark.jpg'
                alt='gradient dark'
                className='h-full w-full'
              />
            </picture> */}
            <div className='items-center justify-between md:flex'>
              <div className='mb-6 md:w-1/2'>
                <h2 className='mb-4 font-display text-2xl text-jacarta-700 dark:text-white sm:text-3xl'>
                  Why not hire Web3 personnel?
                </h2>
                <p className='mb-8 text-lg dark:text-jacarta-300'>
                  There are many people here who know Web3 very well.
                </p>
              </div>
              <Link href='/contact'>
                <a className='btn btn-primary btn-round rounded-full py-3 px-8 text-center font-semibold text-white bg-primary'>
                  Schedule a Call
                </a>
              </Link>
              {/* <img
                src='/images/crypto-consultant/cta_illustration.png'
                alt=''
              /> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end cta --> */}
    </>
  );
};

export default Cta;
