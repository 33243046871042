// ReactQuery
import { useQuery } from "react-query";
// Fetch for Strapi
import { fetchAPI } from "../strapi/fetch-api";
import { upsertAPI } from "../strapi/upsert-api";

/**
 * Create
 */
export async function createSubscriber(params) {
  const res = await upsertAPI(
    "subscribers",
    {
      email: params.email,
    },
    "post"
  );
  console.log("res.log.", res);
  return res;
}

/**
 * Read
 */

// List

// Query for List

// Single

// Query for Single

/**
 * Update
 */

/**
 * Delete
 */
