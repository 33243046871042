// ReactQuery
import { useQuery } from "react-query";
// Fetch for Strapi
import { fetchAPI } from "../strapi/fetch-api";
import { upsertAPI } from "../strapi/upsert-api";

/**
 * Create
 */
export async function createJob(params) {
  const toSnakeCase = (str: string): string => {
    return str.replace(/[A-Z]/g, (char) => `-${char.toLowerCase()}`);
  };

  const res = await upsertAPI(
    "jobs",
    {
      title: params.title,
      position: params.position,
      description: params.description,
      external_link: params.external_link,
      remote_work: params.remote_work,
      slug: `${toSnakeCase(params.title)}_${toSnakeCase(params.position)}`,
      min_salary: params.min_salary,
      max_salary: params.max_salary,
      currency: [parseInt(params.currency)],
      company: [parseInt(params.company)],
      desc_why: params.desc_why,
      desc_role: params.desc_role,
      desc_requirements: params.desc_requirements,
      // images: params.blob,
    },
    "post"
  );
  console.log("res.log.", res);
  return res;
}

/**
 * Read
 */

// List
export function useJobList(options = {}, config = {}) {
  const { data, isLoading, error } = useQuery(
    ["key-job-list", options],
    () => queryJobList(options)
  );
  return {
    jobs: data?.data,
    isLoading,
    error,
  };
}

// Query for List
const queryJobList = async (options) => {
  const response = await fetchAPI("jobs", {
    populate: "*",
    sort: "createdAt:desc",
    locale: options.locale ? options.locale : "en",
  });
  return {
    data: response,
  };
};

// Single
export function useJobById(options = {}, config = {}) {
  const { data, isLoading, error } = useQuery(
    ["key-job-single", options],
    queryJobById
  );
  return {
    jobs: data?.data,
    isLoading,
    error,
  };
}

// Query for Single
const queryJobById = async (params) => {
  const { id } = params?.queryKey[1];

  if (!id) {
    return;
  }
  const response = await fetchAPI("jobs", {
    populate: "*",
    filters: {
      id: { $eq: id },
    },
  });
  return {
    data: response?.data[0],
  };
};

/**
 * Search
 */

export function useJobsByCondition(options = {}, config = {}) {
  const { data, isLoading, error } = useQuery(
    ["key-jobs-by-condition", options],
    filterJobByCondition
  );
  return {
    filteredJobs: data?.data,
    isLoading,
    error,
  };
}

const filterJobByCondition = async (params) => {
  const { id } = params?.queryKey[1].id;
  if (!id) {
    return;
  }
  const response = await fetchAPI("jobs", {
    populate: "*",
    filters: {
      conditions: { $eq: id },
    },
  });
  return {
    data: response,
  };
};

/**
 * Update
 */

/**
 * Delete
 */
