import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import useLocale from "../../hooks/useLocale";
import { createSubscriber } from "../../lib/db/data-subscriber";
import ToastMsg from "../Common/ToastMsg";

const Hero_9 = () => {
  const { t } = useLocale();

  const schema = yup.object().shape({
    email: yup.string().email().required(),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onSubmitForm = (data: {
    email: string;
  }) => {
    console.log("d", data);
    try {
      createSubscriber(data);
      ToastMsg(
        "success",
        "Thank you for subscribing to our newsletter!",
        "success"
      );
      reset();
    } catch (err) {
      console.log("e", err.message);
      ToastMsg("error", err.message, "error");
    }
  };

  const heroImgContent = [
    {
      id: 1,
      positionClass: "bottom-[17%] left-12",
      bgClass: "bg-[#8247E5]/50",
      icon: "/images/chains/polygon@2x.png",
      name: "Polygon",
      currencyMeta: "PY",
    },
    {
      id: 2,
      positionClass: "right-8 top-1/2",
      bgClass: "bg-[#62688F]/50",
      icon: "/images/chains/ethereum@2x.png",
      name: "Ethereum",
      currencyMeta: "ETH",
    },
    {
      id: 3,
      positionClass: "right-1/3 top-1/4",
      bgClass: "bg-[#000000]/50",
      icon: "/images/chains/bitcoin@2x.png",
      name: "Bitcoin",
      currencyMeta: " BTC",
    },
  ];
  return (
    <>
      {/* <!-- Hero --> */}
      <section className='relative h-[85vh]'>
        {/* pc ver image */}
        <img
          src='/images/hero/hero_world.jpg'
          alt='gradient'
          className='absolute inset-0 h-full w-full object-cover hidden md:block'
        />
        {/* sp ver image */}
        <img
          src='/images/hero/hero_world_sp.jpg'
          alt='gradient'
          className='absolute inset-0 h-full w-full object-cover md:hidden'
        />
        {/* hero img */}

        <div className='ml-auto mr-auto h-full max-w-[91rem] px-4'>
          <div className='grid h-full items-center gap-4 md:grid-cols-12'>
            <div className='col-span-6 flex h-full flex-col items-center justify-center py-10 md:items-start lg:py-20 relative'>
              <h1 className='mb-6 text-center font-display text-5xl text-white md:text-left lg:text-6xl'>
                {/* <br /> */}
                <span className='animate-gradient'>{t.HERO.TITLE}</span>
              </h1>
              {/* <p className="mb-8 max-w-md text-center text-lg text-white md:text-left">
                Every digital creation available through MakersPlace is an
                authentic and truly unique.
              </p> */}
              <span className='text-center md:text-left dark:text-jacarta-300 text-accent mb-8 max-w-md'>
                {/* Discover <b>100+</b> remote Web3 Jobs around the world */}
                {t.HERO.DESCRIPTION1}
                <b>{t.HERO.DESCRIPTION2}</b>
                {t.HERO.DESCRIPTION3}
              </span>
              <form
                className='relative max-w-md'
                onSubmit={handleSubmit(onSubmitForm)}>
                <input
                  {...register("email")}
                  type='email'
                  placeholder={t.HERO.PLACEHOLDER}
                  className='w-96 rounded-full border border-jacarta-600 bg-jacarta-700 py-3 px-5 text-white placeholder-gray-600 focus:ring-accent'
                  required
                />
                <button
                  className='btn btn-accent absolute top-0 right-0 rounded-full px-6 py-2 text-sm text-white'
                  type='submit'>
                  {t.HERO.SUBSCRIBE}
                </button>
                {/* error */}
                <small className='block text-center mt-2 text-error'>
                  {errors.email && (errors.email?.message as String)}
                </small>
                <small className='block text-center mt-2'>
                  {
                    t.HERO.SUBSCRIBE_DESCRIPTION
                  }
                </small>
              </form>
              {/* <div className="flex space-x-4">
                <Link href="/collection/explore_collection">
                  <a className="rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
                    Get Started
                  </a>
                </Link>
                <Link href="/collection/explore_collection">
                  <a className="rounded-full bg-white py-3 px-8 text-center font-semibold text-accent shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume">
                    Learn More
                  </a>
                </Link>
              </div> */}
              {/* End button group */}
            </div>
            {/* End .col */}

            <div className='relative col-span-6 col-start-7 hidden h-full md:block'>
              {/* {heroImgContent.map((item) => (
                <div
                  className={`absolute ${item.positionClass} animate-fly`}
                  key={item.id}>
                  <div
                    className={`flex items-center space-x-4 rounded-2lg ${item.bgClass} p-2.5 pr-6 pl-0 backdrop-blur-md`}>
                    <div className='-ml-6 flex h-12 w-12 items-center justify-center rounded-full bg-white'>
                      <img src={item.icon} alt='icon' className='max-w-[50%]' />
                    </div>
                    <span className='font-display text-sm text-white'>
                      {item.name}
                    </span>
                    <span className='font-display text-sm text-white/40'>
                      {item.currencyMeta}
                    </span>
                  </div>
                </div>
              ))} */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end hero --> */}
    </>
  );
};

export default Hero_9;
