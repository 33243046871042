import { useAtom } from "jotai";
import { Condition, ConditionsResponse } from "../../types/strapi/condition";
import { conditionAtom } from "../../atoms/conditions";
import { useEffect } from "react";

const ConditionCloud: React.FC<{
  conditions: ConditionsResponse;
}> = ({ conditions }) => {
  const [selectedCondition, setSelectedCondition] = useAtom(conditionAtom);

  return (
    <>
      <div className='flex flex-wrap justify-center container'>
        {/* select all button */}
        <button
          className={`btn btn-sm btn-outline m-1 ${
            selectedCondition.name === "all" || !selectedCondition.name
              ? "btn-primary"
              : ""
          }`}
          onClick={() => {
            setSelectedCondition({
              id: 0,
              name: "all",
            });
            console.log("selectedCondition", selectedCondition);
          }}>
          {selectedCondition.name === "all" ||
            (!selectedCondition.name && (
              // check icon
              <svg
                className='w-4 h-4 mr-1'
                fill='none'
                stroke='currentColor'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M5 13l4 4L19 7'
                />
              </svg>
            ))}
          ALL
        </button>
        {conditions &&
          conditions?.data?.map((condition: Condition) => (
            <button
              className={`btn btn-sm btn-outline m-1 ${
                condition.id === selectedCondition.id ? "btn-primary" : ""
              }`}
              key={condition.id}
              onClick={() => {
                setSelectedCondition({
                  id: condition.id,
                  name: condition.name,
                });
              }}>
              {condition.id === selectedCondition.id && (
                // check icon
                <svg
                  className='w-4 h-4 mr-1'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M5 13l4 4L19 7'></path>
                </svg>
              )}
              {condition.name}
            </button>
          ))}
      </div>
    </>
  );
};

export default ConditionCloud;
