
/**
 * eslint-disable @next/next/no-img-element
 */

/**
 * eslint-disable @next/next/no-img-element
 */

import React, { useEffect, useState } from "react";
import { collection_activity_item_data } from "../../data/collection_data";
import Link from "next/link";
import Image from "next/image";
import moment from "moment";
import { Job, JobsResponse } from "../../types/strapi/job";
import { Condition } from "../../types/strapi/condition";
import JobView from "../Jobs/JobView";
import JobDetail from "../Jobs/JobDetail";

const JobList: React.FC<any> = ({ jobs }) => {
  console.log("jobs", jobs);

  const [filterVal, setFilterVal] = useState(null);
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const [data, setData] = useState(jobs?.data);
  const [job, setJob] = useState<Job>(null);
  const [filterData, setfilterData] = useState(
    jobs?.data?.map((item) => {
      const { conditions } = item;
      return conditions[0]?.name;
    })
  );

  const [inputText, setInputText] = useState("");
  const handleFilter = (category) => {
    setData(
      collection_activity_item_data.filter((item) => item.category === category)
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newArray = collection_activity_item_data.filter((item) => {
      return item.title.toLowerCase().includes(inputText);
    });
    setData(newArray);
    setInputText("");
  };

  const selectJob = (id: number) => {
    console.log('id', id);
    jobs && setJob(jobs.data.find((job) => job.id === id));
  };

  useEffect(() => {
    setfilterData(filterData.filter(onlyUnique));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    jobs && setJob(jobs.data[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs]);

  return (
    <>
      {/* <!-- Activity Tab --> */}
      <section className='pt-10 pb-24'>
        {jobs?.data?.length == 0 && (
          // <div className='w-full text-center'>
          //   <h1 className='text-3xl font-bold text-gray-800 dark:text-white animate-gradient'>{`${jobs?.data?.length} jobs found!`}</h1>
          // </div>
        // ) : (
          <div className='w-full text-center'>
            <h1 className='text-3xl font-bold text-gray-800 dark:text-white animate-gradient'>
              No Jobs Found
            </h1>
          </div>
        )}

        <div className='container flex flex-row mt-8'>
          {jobs?.data?.length > 0 && (
            <>
              <div className='tab-pane fade md:w-2/5 w-full overflow-scroll'>
                {/* <!-- Records / Filter --> */}
                <div className='lg:flex'>
                  {/* <!-- Jobs --> */}
                  <div className='mb-10 shrink-0 basis-full space-y-5 lg:mb-0 overflow-scroll'>
                    {jobs.data.slice(0, 20).map((job: Job) => {
                      const {
                        id,
                        slug,
                        images,
                        title,
                        conditions,
                        position,
                        publishedAt,
                        min_salary,
                        max_salary,
                        currency,
                        remote_work,
                        salary_duration,
                      } = job as Job;
                      const backendUrl = process.env.NEXT_PUBLIC_STRAPI_API_URL;

                      // Thumbnail format
                      const thumbnailUrl = images
                        ? backendUrl + images[0]?.url
                        : "https://via.placeholder.com/800x600?text=Not%20Found";

                      // Salary format
                      // let salary =
                      //   min_salary && currency
                      //     ? currency.code +
                      //       " " +
                      //       // min_salary を ~~k に変換する
                      //       min_salary / 1000 +
                      //       "k"
                      //     : "";
                      // max_salary
                      //   ? (salary =
                      //       salary + " - " + max_salary / 1000 + "k")
                      //   : "";

                      // Elapsed Days
                      const timeStamp =
                        Date.now() - new Date(publishedAt).getTime();
                      const elapsedDay = Number(
                        Math.floor(timeStamp / 86400000)
                      );

                      return (
                        <div
                          // href={`/job/${slug}`}
                          onClick={() => {
                            selectJob(id);
                          }}
                          key={id}>
                          <div className='dark:bg-jacarta-600 dark:border-jacarta-700 border-jacarta-100 rounded-lg relative border bg-white p-5 transition-shadow hover:shadow-lg'>
                            <div className='flex justify-between'>
                              <div className='flex align-middle'>
                                <img
                                  src={thumbnailUrl}
                                  alt={title}
                                  className='mr-3 self-start w-16 h-16 inset-0 rounded object-cover object-center'
                                  loading='lazy'
                                />

                                <div className='flex flex-col justify-center'>
                                  <h3 className='font-display text-jacarta-700 mb-1 text-base font-semibold dark:text-white'>
                                    {title}
                                  </h3>
                                  <p className='dark:text-jacarta-200 text-jacarta-500 text-xs'>
                                    {position}
                                  </p>
                                </div>
                              </div>

                              <div>
                                {publishedAt && (
                                  <div className='dark:border-jacarta-600 border-jacarta-100 ml-auto rounded-full text-right text-sm flex flex-col align-middle justify-center'>
                                    <p>
                                      {/* {moment(publishedAt).format("YYYY-MM-DD")} */}
                                      <br />
                                      {elapsedDay > 0 && (
                                        <span>
                                          {elapsedDay} <small>d</small>
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className='flex justify-between mt-2'>
                              <div>
                                {remote_work && (
                                  <span className='dark:text-jacarta-200 text-jacarta-500 text-sm'>
                                    Remote
                                  </span>
                                )}
                              </div>
                              <div>
                                {min_salary && (
                                  <span className='dark:text-jacarta-200 text-jacarta-500 text-sm'>
                                    {min_salary ? 
                                    // 万円に変換する
                                    min_salary.toString().slice(0, -4) : ""}万
                                      ~{" "}
                                    {max_salary ? max_salary.toString().slice(0, -4) : ""}万
                                    <small className='mx-1'>
                                      円 / {
                                      salary_duration === "monthly"
                                        ? "月"
                                        : salary_duration === "yearly"
                                        ? "年"
                                        : ""
                                    }
                                    </small>
                                  </span>
                                )}
                              </div>
                            </div>
                            {conditions && conditions.length > 0 && (
                              <span className='text-jacarta-300 text-xs mt-4  flex flex-wrap'>
                                {conditions.map(
                                  (item: Condition, i: number) => {
                                    return (
                                      <span
                                        className='text-xs  m-1 items-center font-bold leading-sm uppercase px-3 py-1 rounded-full border'
                                        key={i}>
                                        {item?.name}
                                      </span>
                                    );
                                  }
                                )}
                              </span>
                            )}
                            <div
                              className={`mt-2 md:hidden flex align-middle justify-end`}>
                              <Link href={`/job/${id}`}>
                                <a className='btn btn-primary btn-sm mt-2 h-8'>
                                  View Detail →
                                </a>
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className='tab-pane fade hidden md:w-3/5 md:block'>
                {jobs && job && <JobDetail job={job} />}
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default JobList;
