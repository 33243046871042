// ReactQuery
import { useQuery } from "react-query";
// Fetch for Strapi
import { fetchAPI } from "../strapi/fetch-api";
import { upsertAPI } from "../strapi/upsert-api";

/**
 * Create
 */
/**
 * Read
 */

// List
export function useConditionList(options = {}, config = {}) {
	const { data, isLoading, error } = useQuery(
		["key-currency-list", options],
		queryConditionList
	);
	return {
    conditions: data?.data,
		isLoading,
		error,
	};
}

// Query for List
const queryConditionList = async () => {
  const response = await fetchAPI("conditions", {
		populate: "*",
		sort: "createdAt:desc",
	});
	return {
		data: response,
	};
};

/**
 * Update
 */

/**
 * Delete
 */
