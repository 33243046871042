/* eslint-disable react-hooks/exhaustive-deps */
import { NextPage } from "next";
import React, { useContext, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import UserContext from "../components/UserContext";
import Meta from "../components/Meta";
import Hero_9 from "../components/hero/hero_9";
import ConditionCloud from "../components/condition/ConditionCloud";
import JobList from "../components/collectrions/JobList";
import Partners from "../components/partners/Partners";
import Cta from "../components/cta/cta";
import { useJobList, useJobsByCondition } from "../lib/db/data-jobs";
import { useConditionList } from "../lib/db/data-conditions";
import { useAtom } from "jotai";
import { conditionAtom } from "../atoms/conditions";
import { fetchAPI } from "../lib/strapi/fetch-api";
import { upsertAPI } from "../lib/strapi/upsert-api";
import useLocale from "../hooks/useLocale";
import Link from "next/link";
import { useRouter } from "next/router";

// Main block
const Home: NextPage = () => {
  const router = useRouter();
  const { scrollRef } = useContext(UserContext);
  const [filteredJobs, setFilteredJobs] = useState<{
    data: any;
    meta: any;
  } | null>();
  const { conditions, isLoading: isLoadingConditions } = useConditionList();
  const [selectedCondition, setSelectedCondition] = useAtom(conditionAtom);
  const { t, locale } = useLocale();
  const { jobs, isLoading } = useJobList(
    {
      locale: locale,
    }
  );

  useEffect(() => {
    window.scrollTo(0, scrollRef.current.scrollPos);
    const handleScrollPos = () => {
      scrollRef.current.scrollPos = window.scrollY;
    };
    window.addEventListener("scroll", handleScrollPos);
    return () => {
      window.removeEventListener("scroll", handleScrollPos);
    };
  });

  useEffect(() => {
    console.log("selectedCondition", selectedCondition);
    console.log("jobs", jobs);
    
    if (jobs && selectedCondition.name === "all") {
      setFilteredJobs(null);
      return;
    }

    jobs ?
      setFilteredJobs({
        data: Object.values(jobs?.data).filter((job: any) => {
          // condition.name === "all" のときは filteredJobs は null にする
          return Object.values(job.conditions).some(
            (condition: any) => condition.id === selectedCondition.id
          );
        }),
        meta: null,
      })
    : jobs;

    console.log("filteredJobs", filteredJobs);
  }, [selectedCondition, locale]);

  return (
    <>
      <Meta title='Web3 Jobs by DXHR｜Blockchain, Smart Contract and Crypto Jobs' />
      <Hero_9 />
      {isLoading && (
        <section>
          <div className='flex justify-center pb-24'>
            <ReactLoading
              type='spinningBubbles'
              color='#999'
              height={120}
              width={120}
            />
          </div>
        </section>
      )}
      {!isLoadingConditions && conditions && (
        <ConditionCloud conditions={conditions} />
      )}

      {!isLoading && jobs && (
        <JobList
          jobs={filteredJobs != null ? filteredJobs : jobs}
          key={selectedCondition.id}
        />
      )}
      {/* {!isLoading && jobs && <Bids jobs={jobs} bgWhite={undefined} />} */}
      <Partners />
      <Cta />
    </>
  );
};

export default Home;
